<template>
  <div class="boxes row text-center container m-auto">
    <div
      v-for="(item, i) in data.content"
      v-bind:key="i"
      class="col-lg-2 col-md-4 col-6 p-0 mx-auto"
    >
      <router-link :to="item.link ? item.link : ''">
        <img
          :src="$assetsUrl + item.image + '?width=240'"
          alt=""
          width="120"
          height="120"
        /><br />{{ item.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
import "./Boxes.scss";

export default {
  data() {
    return {};
  },
  name: "Boxes",
  props: {
    data: Object
  },
  methods: {}
};
</script>
