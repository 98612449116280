import { createWebHistory, createRouter } from "vue-router";
import Page from "@/views/Page.vue";
// import About from "@/views/About.vue";

const routes = [
  {
    path: "/:lang/:slug",
    name: "Page",
    component: Page
  },
  {
    path: "/",
    name: "Homepage",
    component: Page
  },
  {
    path: "/:lang/",
    name: "HomapageLang",
    component: Page
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  // console.log(from)
  // conosle.log(next)
  if (document.querySelector(".dropdown-menu")) {
    if (document.querySelector(".dropdown-menu").classList.contains("show")) {
      document.querySelector(".dropdown-menu").classList.remove("show");
    }
  }
  next();
});

export default router;
