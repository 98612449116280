<template>
  <div class="" v-if="data" :id="data.id">
    <General
      v-for="(item, i) in data.content"
      :data="item"
      v-bind:key="i"
      v-on:reload="loadData"
    />
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import { useStore } from "vuex";

import General from "../components/General/General.vue";

import { getContentBySlug } from "../api/api.js";

export default {
  name: "Page",
  components: {
    General,
  },
  props: {
    initSlug: String,
  },
  data: () => ({
    data: {},
    slug: "",
  }),
  methods: {
    getPage: async function (slug) {
      try {
        return await getContentBySlug("page", slug, this.$lang);
      } catch (ex) {
        console.log(ex);
        return null;
      }
    },
    async loadData() {
      // console.log("RELOAD")
      this.data = false
      this.data = await this.getPage(this.slug);
      // console.log(this.data)

      this.updateSlugs();
    },

    log(msg) {
      console.log(msg);
    },
  },
  setup() {
    const store = useStore();
    function updateSlugs() {
      store.commit("updateSlugs", this.data.slug);
    }
    return { updateSlugs };
  },
  async created() {
    this.slug = this.$route.params.slug
      ? this.$route.params.slug
      : this.initSlug;

    this.loadData();
  },
  watch: {
    $route: {
      handler: async function () {
        if (this.$route.params.lang) {
          this.$lang = this.$route.params.lang;
        }
        this.slug = this.$route.params.slug
          ? this.$route.params.slug
          : this.initSlug;
        this.data = await this.getPage(this.slug);
        this.updateSlugs();
      },
    },
  },
};
</script>
