<template>
  <div class="menu m-0 p-0" v-if="data">
    <nav class="navbar navbar-expand-lg">
      <div class="container container-fluid">
        <h1>
          <a class="navbar-brand me-0" href="/">
            <img
              alt="Stanovák"
              src="../../assets/img/logo_stanovak.svg"
              width="180"
            />
          </a>
        </h1>

        <button
          class="navbar-toggler"
          id="bm-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarContent">
          <ul class="navbar-nav me-auto">
            <li
              v-for="(item, i) in data.content"
              v-bind:key="i"
              class="nav-item drxopdown pe-3 ps-3 ml-0 mr-0"
            >
              <router-link
                v-if="item.schemaName == 'page'"
                class="nav-link"
                @click="closeBm"
                :to="{
                  name: 'Page',
                  params: { lang: this.$lang, slug: item.slug[this.$lang] },
                }"
                >{{ item.title }}</router-link
              >
              <router-link
                v-bind:key="i"
                v-if="item.schemaName == 'submenu'"
                class="nav-link dropdown-toggle"
                :to="{
                  name: 'Page',
                  params: { lang: this.$lang, slug: item.slug[this.$lang] },
                }"
                data-target="#navbarDropdownMenuLink"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                >{{ item.title }}<i class="carret-down"></i
              ></router-link>

              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
                v-if="item.schemaName == 'submenu'"
                v-bind:key="i"
              >
                <router-link
                  v-for="(item, i) in item.content"
                  v-bind:key="i"
                  class="dropdown-item text-center"
                  @click="closeBm"
                  :to="item.href"
                  >{{ item.title }}</router-link
                >
              </div>
              <a
                v-bind:key="i"
                v-if="item.schemaName == 'link'"
                class="nav-link"
                :href="item.href"
                :title="item.hover"
                target="_blank"
                >{{ item.title }}</a
              >
            </li>
          </ul>
          <div class="text-end;">
            <!-- <router-link style="margin:15px;" v-if="sdata.cs" :to="{ name: 'Page', params: { 'lang':'cs', 'slug':sdata['cs']} }">xCZx</router-link>
          <router-link style="margin:15px;" v-else :to="{ name: 'HomapageLang', params: { 'lang':'cs' } }">xCZx</router-link>
          <router-link style="margin:15px;" v-if="sdata.en" :to="{ name: 'Page', params: { 'lang':'en', 'slug':sdata['en']} }">xENx</router-link>
          <router-link style="margin:15px;" v-else :to="{ name: 'HomapageLang', params: { 'lang':'en' } }">xENx</router-link> -->
            <a class="lang" :href="`/cs/${currentSlug['cs']}`"
              ><img src="../../assets/img/cz.png" width="25" /></a
            >&nbsp;
            <a class="lang" :href="`/en/${currentSlug['en']}`"
              ><img src="../../assets/img/en.png" width="25" /></a
            >&nbsp;
            <a class="lang" :href="`/de/${currentSlug['de']}`"
              ><img src="../../assets/img/de.png" width="25"
            /></a>
            &nbsp;
            <a class="lang" :href="`/fr/${currentSlug['fr']}`"
              ><img src="../../assets/img/fr.png" width="25"
            /></a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import "./Menu.scss";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "Menu",
  props: {
    data: Object,
  },
  data() {
    return {
      bmToggler: null,
    };
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
    closeBm() {
      var bsCollapse = new bootstrap.Collapse(this.bmToggler, {
        toggle: false,
      });
      bsCollapse.hide();
    },
  },
  setup() {
    const store = useStore();
    const currentSlug = computed(() => store.state.slugs);
    return { currentSlug };
  },
  mounted() {
    this.bmToggler = document.getElementById("navbarContent");
    // console.log(this.bmToggler);
  },
};
</script>
