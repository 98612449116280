import { createStore } from 'vuex';

export default createStore({
  state () {
    return {
      slugs: {'cs': '', 'en': '', 'de': ''}
    }
  },
  mutations: {
    updateSlugs (state, data) {
      state.slugs = data
    }
  }
})
