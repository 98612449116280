<template>
  <div>
    <div
      v-if="$editor"
      class="position-fixed bottom-0 end-0 p-2"
      style="z-index: 11"
    >
      <div
        id="edit-toast"
        class="toast fade show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <strong class="me-3">Režim editace!</strong>

          <button
            type="button"
            @click="dropEditor"
            class="btn btn-sm btn-primary"
            data-bs-dismiss="toast"
            aria-label="Close"
          >Ukončit    </button>
        </div>
      </div>
    </div>

    <Menu v-if="menuData" :data="menuData" />
    <router-view v-if="initSlug" :initSlug="initSlug" />

  </div>
</template>

<script>
import "bootstrap/scss/bootstrap.scss";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/scss/main.scss";

import Menu from "./components/Menu/Menu.vue";

import { getMenu } from "./api/api.js";

export default {
  name: "App",
  components: {
    Menu
  },
  data: () => ({
    menuData: {},
    initSlug: ""
  }),
  methods: {
    dropEditor() {
      this.$nextTick(() => {
        console.log(this.$route);
      });
      localStorage.removeItem("apiSecret");
      localStorage.removeItem("token");
      location.reload();
    }
  },
  async created() {
    this.$watch(
      () => this.$route.query,
      () => {
        if (this.$route.query.secret && !localStorage.getItem("apiSecret")) {
          localStorage.setItem("apiSecret", this.$route.query.secret);
          localStorage.removeItem("token");
          location.reload();
        } else if (
          this.$route.query.secret &&
          localStorage.getItem("apiSecret")
        ) {
          let query = Object.assign({}, this.$route.query);
          delete query.secret;
          this.$router.push({ query });
        }
      }
    );

    // console.log(this.$route);
    //
    // this.$nextTick(() => {
    //   console.log(this.$route);
    // });
    // if (this.$route.query.secret) {
    //   localStorage.setItem("apiSecret", this.$route.query.secret);
    //   localStorage.removeItem("token");
    //   let query = Object.assign({}, this.$route.query);
    //   delete query.secret;
    //   this.$router.replace({ query });
    //   location.reload();
    // }

    const menu = await getMenu(this.$lang);
    this.menuData = menu[0];
    // console.log(this.menuData);
    this.initSlug = this.menuData.homepage.slug[this.$lang];
  }
};
</script>
<style>
#edit-toast{
  width: auto;
}
</style>
