
export function truncate(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    if (content.length > length){
      var short_content = content.slice(0, length);
      return short_content.slice(0, Math.min(short_content.length, short_content.lastIndexOf(" "))) + clamp;
    }
    else {
      return content;
    }

}
