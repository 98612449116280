<template v-if="data">
  <div
    class="product mt-5"
    v-if="
      (!this.$route.query.kategorie &&
        !this.$route.query.category &&
        !this.$route.query.categorie) ||
      (data.category &&
        (data.category.includes(this.$route.query.kategorie) ||
          data.category.includes(this.$route.query.category) ||
          data.category.includes(this.$route.query.categorie)))
    "
  >
    <div
      v-if="savedNotification"
      class="position-fixed top-0 end-0 p-2"
      style="z-index: 11"
    >
      <div class="toast align-items-center text-white bg-success border-0 show">
        <div class="d-flex">
          <div class="toast-body"><b>Obsah uložen!</b></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-12">
          <div class="row">
            <template v-for="(item, i, j) in data.image">
              <picture v-if="i == 0" class="col-xl-12 main" v-bind:key="i">
                <img
                  @click="show(i)"
                  :src="$assetsUrl + item + '?width=380&quality=85'"
                  :alt="data.title"
                />
              </picture>
              <picture v-else class="col-4 others" v-bind:key="j">
                <img
                  @click="show(i)"
                  :src="
                    $assetsUrl +
                    item +
                    '?width=120&height=90&mode=Crop&quality=85'
                  "
                  :alt="data.title"
                />
              </picture>
            </template>
          </div>
        </div>
        <div class="col-lg-8 col-md-7 col-12">
          <h2 class="title" v-if="$editor">
            <InlineEditor
              class="description"
              @input="save"
              v-model="form.title"
            />
          </h2>

          <h2 class="title" v-else-if="data.title">{{ data.title }}</h2>
          <p class="description" v-if="$editor">
            <InlineEditor v-model="form.text" @input="save" />
          </p>
          <p class="description" v-else v-html="data.text"></p>
          <ul v-if="$editor">
            <li v-for="(item, i) in form.parameters" v-bind:key="i">
              <span> <InlineEditor v-model="item.name" @input="save" class="ib" /></span>
              <span class="value">
                <InlineEditor v-model="item.value" @input="save" class="ib"
              /></span>
            </li>
          </ul>
          <ul v-else>
            <li v-for="(item, i) in data.parameters" v-bind:key="i">
              <span v-html="item.name" class=""></span>
              <span v-html="item.value" class="value"></span>
            </li>
          </ul>
          <div v-if="data.price">{{ data.price }}</div>

          <a
            :href="$assetsUrl + data.doc + '/' + assets[data.doc].slug"
            class="float-end"
            target="_blank"
            v-if="data.doc != '' && assets[data.doc]"
          >
            <img
              alt="PDF"
              src="../../assets/img/pdf.svg"
              class="pdf-download"
            />
          </a>
        </div>
      </div>
    </div>

    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="hide"
    />
  </div>
</template>

<script>
import "./Product.scss";
import { truncate } from "../../helpers/truncate";
import InlineEditor from "../InlineEditor.vue";
import { getAssetsInfo, updateContent } from "../../api/api.js";

export default {
  name: "Product",
  components: {
    InlineEditor,
  },
  data() {
    return {
      form: null,
      visible: false,
      index: 0,
      assets: {},
      savedNotification: false,
      imgs: this.data.image.map(
        (x) => this.$assetsUrl + x + "?width=1920&quality=85"
      ),
    };
  },
  props: {
    data: Object,
    perexLength: Number,
  },
  async created() {
    if (this.$editor) {
      this.form = { ...this.data };
    }
    const id = this.data.doc[0]
    const info = await getAssetsInfo(id)
    this.assets[id] = info.items[0]
  },
  methods: {
    truncate,
    getAssetsInfo(ids){
      console.log(getAssetsInfo(ids))
    },
    async getAssetSlug(ids){
      const info = await getAssetsInfo(ids)
      console.log(info.items[0].slug)
      return await info.items[0].slug
    },
    log(msg) {
      console.log(msg);
    },
    show(i) {
      this.index = i;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    save() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1500);
    },
    async saveAction() {
      console.log(this.data.cmsid);
      if (
        // TO-DO:
        await updateContent("product", this.data.cmsid, {
          title: { [this.$lang]: this.form.title },
          text: { [this.$lang]: this.form.text },
          parameters: { [this.$lang]: this.form.parameters },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
  },
};
</script>

<style>
</style>
