<template v-if="data">
  <div class="image container-fluid p-0 m-0">
    <vueper-slides
      :slideRatio="440 / 1920"
      :breakpoints="breakpoints"
      autoplay
      fade
      transition-speed="1000"
      duration="5000"
    >
      <vueper-slide
        v-for="(slide, i) in data.images"
        :key="i"
        :image="$assetsUrl + slide + '?width=1920&height=440&mode=Crop&quality=85'"
      />
    </vueper-slides>
  </div>
</template>

<script>
import "./Image.scss";

import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      breakpoints: {
        1000: {
          slideRatio: 550 / 1920
        },
        800: {
          slideRatio: 660 / 1920
        },
        600: {
          slideRatio: 880 / 1920
        },
        400: {
          slideRatio: 1100 / 1920
        }
      }
    };
  },
  name: "Image",
  props: {
    data: Object,
    perexLength: Number
  },
  methods: {}
};
</script>
