import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueEasyLightbox from "vue-easy-lightbox";

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueEasyLightbox);

/// LANGUGAGE SET ///
let lang = process.env.VUE_APP_DEFAULT_LANG; // default language by .env file
if (
  process.env.VUE_APP_LANGS.split(",").includes(
    window.location.pathname.split("/")[1]
  )
) {
  //override with language in URL if valid
  lang = window.location.pathname.split("/")[1];
  localStorage.setItem("lang", lang);
} else if (localStorage.getItem("lang")) {
  // or is alredy set in local storage
  lang = localStorage.getItem("lang");
}
/// END OF LANGUGAGE SET  ///

app.config.globalProperties.$langs = process.env.VUE_APP_LANGS.split(",");
app.config.globalProperties.$lang = lang;
app.config.globalProperties.$assetsUrl = process.env.VUE_APP_ASSETS_URL;
app.config.globalProperties.$editor = localStorage.getItem('apiSecret') ? true : false;

app.mount("#app");
