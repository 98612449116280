<template v-if="data">
  <div class="gallery container-lg mt-5">
    <div
      v-if="savedNotification"
      class="position-fixed top-0 end-0 p-2"
      style="z-index: 11"
    >
      <div class="toast align-items-center text-white bg-success border-0 show">
        <div class="d-flex">
          <div class="toast-body"><b>Obsah uložen!</b></div>
        </div>
      </div>
    </div>
    <!-- {{data.title}} -->
    <div v-if="$editor">
      <draggable
        class="row row--dense"
        animation="250"
        v-model="form.images"
        item-key="id"
        draggable=".img"
        @sort="onSort"
      >
        <template #item="{ element, index }">
          <div class="img col-md-4 col-sm-6 col-xs-12 p-1">
            <picture>
              <img
                @click="this.show(index)"
                :src="
                  $assetsUrl +
                  element +
                  '?width=370&height=280&mode=Crop&quality=85'
                "
                :alt="data.Title"
              />
              <div class="img-ctrl">
                <span @click="remove(index)" class="p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    /></svg
                ></span>
              </div>
            </picture>
          </div>
        </template>
        <div>aaa</div>
      </draggable>
      <div v-if="isSaving" class="filter"></div>
      <div
        v-if="isSaving"
        class="d-flex justify-content-center align-items-center loading"
      >
        <div class="spinner-border spinner-border" role="status"></div>
      </div>
      <div
        class="
          dnd-placeholder
          mb-3
          justify-content-center
          align-items-center
          flex-column
          d-flex
        "
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div class="mt-1" style="color: #888">
          <small
            >Nahrajte obrázky přetáhnutím sem, nebo pomocí tlačítka:
          </small>
        </div>
        <label for="file-upload" class="custom-file-upload">
          <div class="btn btn-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            <i class="carret-down"></i>
          </div>
        </label>
        <input
          id="file-upload"
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="upload"
          accept="image/*"
          class="input-file"
        />
      </div>
    </div>
    <div class="row" v-else>
      <div
        class="img col-md-4 col-sm-6 col-xs-12 p-1"
        v-for="(item, i) in data.images"
        v-bind:key="i"
      >
        <picture>
          <img
            @click="this.show(i)"
            :src="
              $assetsUrl + item + '?width=370&height=280&mode=Crop&quality=85'
            "
            :alt="data.Title"
          />
        </picture>
      </div>
    </div>

    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="hide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import "./Gallery.scss";
import draggable from "vuedraggable";
import { updateContent, uploadAsset } from "../../api/api.js";

export default {
  name: "Gallery",
  components: {
    draggable,
  },
  data() {
    return {
      visible: false,
      index: 0,
      form: Object,
      drag: false,
      isSaving: false,
      imgs: this.data.images.map((x) => this.$assetsUrl + x + "?quality=85"),
    };
  },
  props: {
    data: Object,
  },
  created() {
    this.form = this.data;
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
    show(i) {
      this.index = i;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    onSort() {
      console.log(this.form.images);

      this.imgs = this.data.images.map(
        (x) => this.$assetsUrl + x + "?quality=85"
      );
      if (
        updateContent("gallery", this.data.cmsid, {
          images: { iv: this.data.images },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("active")) {
        event.currentTarget.classList.add("active");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("active");
    },
    drop(event) {
      event.preventDefault();
      // this.$refs.file.files = event.dataTransfer.files;
      this.filesUpload(event.dataTransfer.name, event.dataTransfer.files);
      // Clean up
      event.currentTarget.classList.remove("active");
      this.isSaving=true
    },
    upload(e) {
      this.filesUpload(e.target.name, e.target.files);
      this.isSaving=true
    },

    async filesUpload(fieldName, fileList) {
      // const fieldName = e.target.name
      // const fileList = e.target.files

      // const fileCount = e.target.files.length
      console.log(fieldName);

      console.log(fileList);
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;
      // formData.append();
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append("File", fileList[x], fileList[x].name);
      });

      // save it
      // this.save(formData);
      console.log(...formData);
      const result = await uploadAsset(formData);
      if (result) {
        console.log(result);
        console.log(result.id);
        this.form.images.push(result.id);
        this.imgs = this.form.images.map(
          (x) => this.$assetsUrl + x + "?quality=85"
        );
        updateContent("gallery", this.data.cmsid, {
          images: { iv: this.form.images },
        });
        this.isSaving=false
      }
    },
    remove(i) {
      if (confirm("Potvrďte smazání")) {
        let images = this.data.images;
        // images.remove(i)
        images.splice(i, 1);
        // console.log(images)
        updateContent("gallery", this.data.cmsid, { images: { iv: images } });
      }
      // console.log(this.data.images[i])
      // removeContent('gallery', this.data.images[i])
    },
  },
};
</script>
