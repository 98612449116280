<template v-if="data">
  <div class="text container mt-5">
    <div
      v-if="savedNotification"
      class="position-fixed top-0 end-0 p-2"
      style="z-index: 11"
    >
      <div class="toast align-items-center text-white bg-success border-0 show">
        <div class="d-flex">
          <div class="toast-body"><b>Obsah uložen!</b></div>
        </div>
      </div>
    </div>
    <h2 class="title" v-if="$editor && (form.title || group)">
      <InlineEditor class="description" @input="save" v-model="form.title" />
    </h2>
    <h2 class="title" v-else-if="data.title">{{ data.title }}</h2>
    <p class="text-black-50" v-if="$editor && group">
      <date-picker
        type="date"
        locale="cs-CZ"
        autoApply
        :clearable="false"
        :enableTimePicker="false"
        format="dd.MM.yyyy"
        v-model="publishDate"
        @input="save"
      />
    </p>
    <p class="text-black-50" v-else-if="data.publishDate">{{ formatDate(data.publishDate) }}</p>
    <p
      v-if="perexLength > 0"
      class="description"
      v-html="truncate(data.text, perexLength)"
    ></p>

    <InlineEditor
      class="description"
      v-else-if="$editor"
      @input="save"
      v-model="form.text"
    />

    <p v-else class="description" v-html="data.text"></p>
    <div v-if="$editor && group" class="text-end mb-2 mt-1">
      <div class="btn btn-sm btn-danger" @click="removeText(form.cmsid)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-trash"
          viewBox="0 0 16 16"
        >
          <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
          />
          <path
            fill-rule="evenodd"
            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import "./Text.scss";
import { truncate } from "../../helpers/truncate";
import { formatDate } from "../../helpers/formatDate";
import { updateContent } from "../../api/api.js";
import InlineEditor from "../InlineEditor.vue";
import DatePicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

export default {
  name: "Text",
  components: {
    InlineEditor,
    DatePicker,
  },
  data() {
    return {
      form: null,
      savedNotification: false,
      publishDate: null,
    };
  },
  props: {
    data: Object,
    perexLength: Number,
    group: {type: Boolean, default: false}
  },
  created() {
    if (this.$editor) {
      this.form = { ...this.data };
      this.publishDate = new Date(this.data.publishDate);
    }
  },
  methods: {
    truncate,
    formatDate,
    save(content) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1500, content);
    },
    async saveAction() {
      if (
        await updateContent("text", this.data.cmsid, {
          text: { [this.$lang]: this.form.text },
          title: { [this.$lang]: this.form.title },
          publishDate: { iv: this.publishDate.toISOString() },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
    removeText(id){
      this.$emit('remove', id)
    }
  },
};
</script>
